<template>
  <div>
    <v-dialog width="650" v-model="dialog" persistent>
      <v-card class="customRruleDialog">
        <v-card-title class="pa-3 pb-2 primary white--text">
          <span class="body-1 font-weight-medium"> Custom recurrence </span>
          <v-spacer></v-spacer>
          <v-btn icon dark small>
            <v-icon size="22"> mdi-information </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pa-3 pl-0 pa-sm-3">
          <div>
            <!-- start -->
            <v-layout row wrap align-center class="ma-0 mb-2">
              <v-flex lg3 sm3 xs3 class="text-right pr-3 px-sm-4">
                <label class="font-weight-medium body-2 black--text"> Start </label>
              </v-flex>
              <v-flex lg8 sm7 xs9>
                <date-time-picker v-model="ruleObj.start" :key="reRenderKey"></date-time-picker>
              </v-flex>
            </v-layout>
            <!-- Repeat -->
            <v-layout row wrap align-center :class="['ma-0', (ruleObj.repeatWhen === 'Day') ? 'mb-2' : '']">
              <v-flex lg3 sm3 xs3 class="text-right pr-3 px-sm-4">
                <label class="font-weight-medium body-2 black--text"> Repeat every </label>
              </v-flex>
              <v-flex lg2 sm2 xs3 class="pr-1">
                <v-text-field outlined dense hide-details v-model.number="ruleObj.dayNumber" @keypress="positiveNumberValidation"
                @change="ruleObj.dayNumber = ruleObj.dayNumber || 1"></v-text-field>
              </v-flex>
              <v-flex lg3 sm5 xs6>
                <v-select outlined dense hide-details v-model="ruleObj.repeatWhen" :items="['Day', 'Week', 'Month', 'Year']"></v-select>
              </v-flex>
            </v-layout>
            <v-layout row wrap align-center :class="['ma-0', (ruleObj.repeatWhen === 'Week') ? 'mb-2' : '']"
            v-if="ruleObj.repeatWhen !== 'Day'">
              <v-flex lg3 sm3 xs3></v-flex>
              <v-flex lg9 sm9 xs9 v-if="ruleObj.repeatWhen === 'Week'">
                <div class="mt-1">
                  <v-btn-toggle v-model="ruleObj.weekGroup" mandatory multiple>
                    <v-btn v-for="(day, index) in ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']" :key="index"
                    :small="!xsOnly" :x-small="xsOnly" fab :value="day" plain active-class="weekGroup-item--active">
                      <span class="font-weight-medium caption text-capitalize"> {{ day }} </span>
                    </v-btn>
                  </v-btn-toggle>
                </div>
              </v-flex>
              <v-flex lg9 sm9 xs9 v-else-if="ruleObj.repeatWhen === 'Month'">
                <div>
                  <v-radio-group dense v-model="ruleObj.onMonthCondition" column mandatory hide-details>
                    <!-- onMonth rule has been hidden due to indevelopment -->
                    <v-radio v-for="(item, index) in ['onDay', 'onMonth']" :key="index" :value="item" v-show="item !== 'onMonth'">
                      <template #label>
                        <div class="ml-n1">
                          <v-layout row wrap align-center class="ma-0">
                            <v-flex :lg3="!index" :lg2="index">
                              <span class="font-weight-medium"> {{ index ? 'On the' : 'On day' }} </span>
                            </v-flex>
                            <!-- onMonth fields (hidden) -->
                            <v-flex lg4 class="ml-n2 pr-1" v-if="index">
                              <div class="ml-n2">
                                <v-select outlined dense hide-details v-model="ruleObj.onWhichMonthPart"
                                :items="monthParts" disabled></v-select>
                              </div>
                            </v-flex>
                            <v-flex lg5 class="pr-2" v-if="index">
                              <div>
                                <v-select outlined dense hide-details v-model="ruleObj.onWhichWeekday"
                                :items="weekdays" disabled></v-select>
                              </div>
                            </v-flex>
                            <!-- onDay fields -->
                            <v-flex lg9 xs9 v-else>
                              <v-text-field outlined dense hide-details v-model="ruleObj.onDayNumber" @keypress="positiveNumberAndCommaOnlyValidation"
                              @change="ruleObj.onDayNumber = ruleObj.onDayNumber || '1'"></v-text-field>
                            </v-flex>
                          </v-layout>
                        </div>
                      </template>
                    </v-radio>
                  </v-radio-group>
                </div>
              </v-flex>
              <v-flex lg9 sm9 xs9 v-else-if="ruleObj.repeatWhen === 'Year'">
                <div>
                  <v-radio-group dense v-model="ruleObj.onYearCondition" column mandatory hide-details>
                    <!-- onDayMonth rule has been hidden due to indevelopment -->
                    <v-radio v-for="(item, index) in ['onMonth', 'onDayMonth']" :key="index" :value="item" v-show="item !== 'onDayMonth'">
                      <template #label>
                        <div class="ml-n1">
                          <v-layout row wrap align-center class="ma-0">
                            <v-flex lg2 sm2 xs2>
                              <span class="font-weight-medium"> {{ index ? 'On the' : 'On' }} </span>
                            </v-flex>
                            <!-- onDayMonth fields (hidden) -->
                            <v-flex lg3 class="ml-n2" v-if="index">
                              <div class="ml-n1">
                                <v-select outlined dense hide-details v-model="ruleObj.onWhichMonthPart"
                                :items="monthParts" disabled></v-select>
                              </div>
                            </v-flex>
                            <v-flex lg4 class="px-1" v-if="index">
                              <div>
                                <v-select outlined dense hide-details v-model="ruleObj.onWhichWeekday"
                                :items="weekdays" disabled>
                                  <template #append-outer>
                                    <span class="body-2 ml-n1 pr-1 mt-1 grey--text text--darken-2"> Of </span>
                                  </template>
                                </v-select>
                              </div>
                            </v-flex>
                            <v-flex lg3 v-if="index">
                              <div>
                                <v-select outlined dense hide-details v-model="ruleObj.onWhichDayMonth"
                                :items="monthsList" disabled></v-select>
                              </div>
                            </v-flex>
                            <!-- onMonth fields -->
                            <v-flex lg4 sm4 xs6 class="ml-md-n7 ml-sm-n7" v-if="!index">
                              <v-select outlined dense hide-details v-model="ruleObj.onWhichMonth"
                              :items="monthsList"></v-select>
                            </v-flex>
                            <v-flex lg6 sm4 xs4 class="pl-1 pr-sm-1" v-if="!index">
                              <v-text-field outlined dense hide-details v-model="ruleObj.onDayNumber" @keypress="positiveNumberAndCommaOnlyValidation"
                              @change="ruleObj.onDayNumber = ruleObj.onDayNumber || '1'"></v-text-field>
                            </v-flex>
                          </v-layout>
                        </div>
                      </template>
                    </v-radio>
                  </v-radio-group>
                </div>
              </v-flex>
            </v-layout>
            <!-- end -->
            <v-layout row wrap align-center class="ma-0 mb-2">
              <v-flex lg3 sm3 xs3 class="text-right pr-3 px-sm-4">
                <label class="font-weight-medium body-2 black--text"> End </label>
              </v-flex>
              <v-flex lg8 sm7 xs9>
                <date-time-picker v-model="ruleObj.end" :key="reRenderKey"></date-time-picker>
              </v-flex>
            </v-layout>
            <!-- exdate -->
            <v-layout row wrap align-center class="ma-0">
              <v-flex lg3 sm3 xs3 class="text-right pr-3 px-sm-4">
                <label class="font-weight-medium body-2 black--text"> Exclude date(s) </label>
              </v-flex>
              <v-flex lg8 sm7 xs9>
                <v-menu
                  ref="menu"
                  v-model="ruleObj.exdateMenu"
                  :close-on-content-click="false"
                  :return-value.sync="ruleObj.exdate"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template #activator="{ on, attrs }">
                    <v-combobox v-model="ruleObj.exdate" multiple chips small-chips label="Exclude date(s)"
                    hide-details readonly v-bind="attrs" v-on="on" outlined dense append-icon=""
                    @click.stop.native="ruleObj.exdateMenu = true">
                      <template #selection="{ item }">
                        <v-chip small color="primary" outlined class="mt-1"> {{ $_formatDate(item, 'YYYY-MM-DD', 'DD.MM.YYYY') }} </v-chip>
                      </template>
                      <template #append v-if="ruleObj.exdate && ruleObj.exdate.length">
                        <v-btn icon text small class="custom-append-clear" @click.stop="ruleObj.exdate = []">
                          <v-icon small> mdi-close-circle </v-icon>
                        </v-btn>
                      </template>
                    </v-combobox>
                  </template>
                  <v-date-picker v-model="ruleObj.exdate" multiple no-title scrollable>
                    <v-spacer></v-spacer>
                    <div class="mt-n3 mb-n1">
                      <v-btn text small color="error" @click="ruleObj.exdateMenu = false"> Cancel </v-btn>
                      <v-btn text small color="primary" @click="$refs.menu.save(ruleObj.exdate)"> OK </v-btn>
                    </div>
                  </v-date-picker>
                </v-menu>
              </v-flex>
            </v-layout>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" class="text-capitalize" @click="onDialogSave"> Save </v-btn>
          <v-btn outlined color="error" class="text-capitalize" @click="onDialogClose"> Close </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: ['start', 'end', 'payload'],
  data () {
    return {
      dialog: false,
      ruleObj: {},
      reRenderKey: 1,
      weekdays: [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
      ],
      monthParts:[
        'First',
        'Second',
        'Third',
        'Fourth',
        'Last'
      ]
    }
  },
  components: {
    'date-time-picker': () => import('@/components/DateTimePicker.vue')
  },
  watch: {
    'dialog' (value) {
      if (value) {
        if (this.payload) {
          this.ruleObj = this.$_copy(this.payload)
        } else {
          this.ruleObj = {
            start: this.start,
            end: this.end,
            dayNumber: 1,
            repeatWhen: 'Day',
            onDayNumber: 1,
            onWhichMonthPart: 'First',
            onWhichWeekday: 'Sunday',
            onWhichMonth: 1,
            onWhichDayMonth: 1
          }
        }
        this.dialog = true
      } else this.$emit('close')
    }
  },
  methods: {
    onDialogSave () {
      let rule = this.$_copy(this.ruleObj)
      const ruleObj = {
        dtstart: rule.start,
        until: rule.end,
        freq: (rule.repeatWhen === 'Day')
          ? 'daily'
          : `${rule.repeatWhen.toLowerCase()}ly`,
        interval: rule.dayNumber,
        byweekday: (rule.repeatWhen === 'Week')
          ? rule.weekGroup.map((item) => item.toLowerCase())
          : undefined,
        bymonthday: ((rule.repeatWhen === 'Month' && rule.onMonthCondition === 'onDay') || (rule.repeatWhen === 'Year' && rule.onYearCondition === 'onMonth'))
          ? rule.onDayNumber.split(',').map((dayNum) => +dayNum).filter((item) => !!item)
          : undefined,
        bymonth: (rule.repeatWhen === 'Year' && rule.onYearCondition === 'onMonth')
          ? [rule.onWhichMonth]
          : undefined
      }
      // console.log(rule, ruleObj)
      this.$emit('save', {
        ruleObj,
        payload: this.ruleObj,
        dialog: {
          close: () => {
            this.dialog = false
          }
        }
      })
    },
    onDialogClose () {
      this.$emit('cancel', {
        payload: this.ruleObj,
        dialog: {
          close: () => {
            this.dialog = false
          }
        }
      })
    }
  }
}
</script>
<style>
  .customRruleDialog .weekGroup-item--active {
    background: #E3F2FD;
    color: white;
  }
  .customRruleDialog .v-input--radio-group {
    margin-top: 7px !important;
  }
  .customRruleDialog .custom-append-clear {
    margin: -4.8px -7px 0 0 !important;
  }
</style>
